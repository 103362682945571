<template>
  <div class="col main_topics_wrapper_container">
    <!-- Start Server Table -->

    <server-table
      class=""
      :count="usersCategoriesCount"
      :DataItems="usersCategories"
      :loading="loadingValue"
      @Refresh="getUserCategoriesData"
      @reset="filter = {}"
      :filter="filter"
      :title="`${localization('Topics')}`"
      edit_permission="add_topics"
    >
      <template #columns>
        <el-table-column
          prop="name"
          sortable
          :label="`${localization('Name')}`"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="institution_name"
          :label="`${localization('Institution')}`"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="category"
          label="category"
          min-width="150"
          sortable
        ></el-table-column>

        <el-table-column prop="" label="_" width="140">
          <template slot-scope="scope">
            <div class="td-actions">
              <router-link
                :to="`/user-categories/${scope.row.id}`"
                v-tooltip.top-center="`${localization('Show category')}`"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i>
              </router-link>
              <a
                @click="open_topic_dialog(2, scope.row, scope.row.id)"
                v-tooltip.top-center="`${localization('Edit category')}`"
                class="btn btn-warning btn-link btn-xs"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                @click="removeUserTask(scope.row.id)"
                v-tooltip.top-center="`Delete category`"
                :class="`btn btn-danger btn-link btn-xs`"
              >
                <i class="fa fa-times"></i>
              </a>
              <a
                @click="removeAllTaskUsers(scope.row.id)"
                v-tooltip.top-center="`Delete all users from category`"
                :class="`btn btn-danger btn-link btn-xs`"
              >
                <i class="fa fa-trash"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #action>
        <div type="info" class="ml-2" @click="open_topic_dialog(1, '', null)">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Category") }}
        </div>
      </template>
    </server-table>
    <!-- End Server Table  -->

    <el-dialog
      :title="
        type == 1
          ? `${localization('Add Category')}`
          : `${localization('Edit Category')}`
      "
      width="60%"
      :visible.sync="topic_dialog"
      top="4vh"
      class="add_topics_dialog"
    >
      <el-form
        :model="taskData"
        ref="ruleForm"
        :rules="rules"
        label-position="top"
      >
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <el-form-item
              :label="`${localization('Name')}`"
              label-width="100px"
              prop="name"
            >
              <el-input
                v-model="taskData.name"
                :placeholder="`${localization('Name')}`"
                autocomplete="off"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6  col-sm-12">
            <el-form-item
              :label="`${localization('Institution')}`"
              label-width="100px"
              prop="organization_id"
            >
              <el-select
                v-model="taskData.organization_id"
                :placeholder="`${localization('Institution')}`"
                clearable
                class="select-default mt-2 w-100"
              >
                <el-option
                  v-for="cat in orgsInstitutionsData"
                  :key="cat.id"
                  :label="cat.name"
                  :value="cat.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <!-- Task Category  -->
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="topic_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          class="text-center text-capitalize save_button "
          type=""
          @click="save"
          >{{ localization("Save") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      filter: {},
      taskData: {
        name: "",
        id: null,
        organization_id: null
      },
      type: null,
      topic_dialog: false,
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        organization_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  components: { ServerTable },
  // getUsersCategories
  mounted() {
    this.getUserCategoriesData();
    this.$store.dispatch("organization/orgs");
  },
  methods: {
    getUserCategoriesData(query) {
      this.$store.dispatch("topics/handlerUserCategories", { query: query });
      this.$store.dispatch("topics/handlerUserCategoriesDetails", 2);
    },
    open_topic_dialog(type, value, id) {
      this.taskData.name = value.name;
      this.taskData.organization_id = value.institution_id;
      this.taskData.id = id;
      this.topic_dialog = true;
      this.type = type;
    },
    save() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("topics/addUserTask", {
                name: this.taskData.name,
                organization_id: this.taskData.organization_id
              })
              .then(_ => {
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Done",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.getUserCategoriesData();
                this.topic_dialog = false;
              });
          } else {
            this.$store
              .dispatch("topics/addUserTask", {
                id: this.taskData.id,
                name: this.taskData.name,
                organization_id: this.taskData.organization_id
              })
              .then(_ => {
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Done",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.getUserCategoriesData();
                this.topic_dialog = false;
              });
          }
        } else {
          return false;
        }
      });
    },

    removeUserTask(id) {
      Swal.fire({
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("topics/deleteUserTask", id).then(_ => {
            this.getUserCategoriesData();
            Swal.fire({
              title: "",
              text: `${this.localization("Deleted")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              type: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
              icon: "success"
            });
          });
        }
      });
    },
    removeAllTaskUsers(id) {
      Swal.fire({
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("topics/deleteAllUsersTask", id).then(_ => {
            this.getUserCategoriesData();
            Swal.fire({
              title: "",
              text: `${this.localization("Deleted")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              type: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
              icon: "success"
            });
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      loadingValue: ["topics/usersLoad"],
      usersCategories: ["topics/getUsersCategories"],
      usersCategoriesCount: ["topics/getUsersCategoriesCount"],
      orgsInstitutionsData: ["organization/getOrgsData"]
    })
  }
};
</script>

<style lang=""></style>
